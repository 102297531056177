.tileCard-textImportant:empty {
  padding: 0;
}

.tileCard-content {
  padding-bottom: 24px;
}

.tileCard-extras {
  width: 100%;
  max-width: 280px;
  padding: 10px;
  margin-top: 16px;
  opacity: 0.6;
  transition: opacity 0.1s;
  background-color: #ebebeb;
}

.tileCard:hover .tileCard-extras {
  opacity: 1;
}

.tileCard-attribute:not(:last-child) {
  margin-bottom: 8px;
}

.tileCard-attributeName {
  font-size: 14px;
  font-weight: bold;
}

.tileCard-scoreTrack {
  display: block;
  border: solid 1px #ccc;
  padding: 1px;
  width: 100%;
}

.tileCard-scoreBar {
  height: 5px;
  background: #333;
}

.tileCard-scoreLabels {
  display: flex;
  gap: 8px;
  justify-content: space-between;
  font-size: 10px;
  text-transform: uppercase;
}

.tileCard-texts {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.tileCard-text:not(:last-child) {
  margin-bottom: 16px;
}

.tileCard-tileInfo {
  margin-top: auto;
}
