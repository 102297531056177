/*
  TODO: Remove once Rawlings customizers
  switch to the new UI.
*/

.Layout,
.Layout-select,
.Layout-rendererBackground,
.finalize-preview {
  background: #ffffff;
}

.wizard-scrollItem:last-of-type {
  padding-bottom: 0;
}

.scroll-menu .tile-content {
  background-size: cover;
}
